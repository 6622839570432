@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  max-width: 100vw;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}

button,
input {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #ffffff;
  padding-top: 60px;
}

/* navbar */
.nav-bar {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #161616;
  color: #e7e7e7;
  height: 60px;
  position: fixed;
  box-shadow: #000 0px 0px 2px;  
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.sign-in {
  border: none;
  background-color: transparent;
}
.sign-in > img {
  height: 30px;
  width: auto;
}
.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #e60000;
  font-weight: 600;
  margin: 0;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #050505;
  background-color: #222222;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

.messages-wrapper {
  padding: 30px;
  margin-bottom: 60px;
}

.chat-bubble {
  border-radius: 20px 20px 20px 20px;
  padding: 15px;
  background-color: #131313;
  color: #ffffff;
  width: max-content;
  max-width: calc(100% - 50px);
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 20px 20px;
  background-color: #0a0a0a;
}

.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #ffffff;
}

.user-message {
  word-break: break-all;
  color: #ffffff;
}

.message-time {
  display: block;
  text-align: right;
  font-size: 0.8rem;
  color: #8f8f8f;
}

.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  color: #ffffff;
  padding: 20px 30px;
  background-color: #1a1a1a;
  box-shadow: #000 0px 0px 4px;  
  display: flex;
}

.send-message > input {
  height: 50px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: rgb(24, 24, 24);
  color: #ffffff;
  font-size: 1rem;
}
.send-message > input:placeholder {
  color: #ddd;
}
.send-message > :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #000000;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px 5px 5px 5px;
  color: #ffffff;
  border: 1px solid #000000;
  background-color: #292929;
  font-weight: 600;
}
.send-message > button:hover {
  background-color: #000;
  color: #ffffff;
  transition: 2s;
}
.img-send {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.button-upload {
  /* Reset OS-specfic / browser-specific default style (see https://ishadeed.com/article/styling-the-good-old-button/#the-default-styles) */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgb(255, 0, 0); /* to be replaced with your custom design */
  border: 1px solid currentColor; /* to be replaced with your custom design */
  /* Signify interactivity */
  border-radius: 8px; /* no physical button has a sharp corner; exact value to be replaced for your custom design */
  cursor: pointer;
  /* Match font style with the parent element */
  font: inherit;
  /* Make the button easy for touchscreen users to tap */
  min-height: 48px;
  min-width: 48px;
}
.button-upload:focus {
  outline-offset: 4px; /* make Chrome show a focus ring around the button */
}
.uploaded-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.welcome_text {
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
}

.App {
  background-color: #222222;
  color: #ffffff;
}
.text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
}
.auth-button {
  background-color: #161616;
  color: #ffffff;
  max-width: fit-content;
  max-height: fit-content;
  padding: 20px;
  border-radius: 10px;
  margin: auto;
}

p {
  color: #aaaaaa;
}
.tos {
  margin-bottom: 0;
  padding: 10px;
}
.tos a {
  color: #ffffff;
  text-decoration: none;
}
.welcome img{
  width: 60%;
  height: auto;
  margin-top: 20px;
}
.emoji-picker {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1;
}